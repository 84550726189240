import React from 'react'
import {Link} from  'react-router-dom'
import abimg from '../../images/about2.jpg'
import abimg2 from '../../images/about3.jpg'
import abimg3 from '../../images/ab-shape-3.png'

import rrImg from '../../images/no-revisions-uH1EgiuWtY8-unsplash.jpg'
import imgOther from '../../images/home-img-other.jpg'


const AboutS3 = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


    return(
        <section className="wpo-about-section-s3">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={rrImg} alt=""/>
                                <div className="wpo-total-raised">
                                    <div className="wpo-total-raised-wrap">
                                        <div className="wpo-total-raised-icon">
                                            <i className="fi flaticon-wallet-filled-money-tool"></i>
                                        </div>
                                        <div className="wpo-total-raised-text">
                                            <ul>
                                                <li>Total Saved<span>$25000</span></li>
                                            </ul>
                                            <div className="progress-section">
                                                <div className="process">
                                                    <div className="progress">
                                                        <div className="progress-bar">
                                                            <div className="progress-value"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <span>Our Mission</span>
                            <h2>We help you realise your goals.</h2>
                            <p>The aim of the organization is applied financial literacy, by providing the support structure, knowledge, 
                                skills and confidence for individuals to make responsible financial decisions to achieve their goals, take 
                                advantage of available opportunities, and live better lives. The DHF holds and uses three key principles to 
                                drive success:
                            </p>
                            <ul>
                                <li>Community focused</li>
                                <li>Current and Relevant</li>
                                <li>Learner-centred</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ab-left-img">
                <img src={imgOther} alt=""/>
            </div>
            <div className="ab-right-img">
                <img src={abimg3} alt=""/>
            </div>
        </section>
    )
}

export default AboutS3;