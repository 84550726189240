import React, { Component } from 'react'


class ContactForm extends Component {


    state = {
        name: '',
        email: '',
        lastname: '',
        message: '',
        error: {}
    }


    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    submitHandler = (e) => {
        e.preventDefault();
        console.log('state=', this.state)

        const { name,
            email,
            lastname,
            message, error } = this.state;

        if (name === '') {
            error.name = "Please enter your name";
        }
        if (email === '') {
            error.email = "Please enter your email";
        }
        if (lastname === '') {
            error.lastname = "Please enter your Lastname";
        }
        if (message === '') {
            error.message = "Please enter your message";
        }


        if (error) {
            this.setState({
                error
            })
        }
        if (error.name === '' && error.email === '' && error.lastname === '' && error.message === '') {
            
            //submit!
            alert('Something went wrong! Please send a direct email to hello@dreamershub.org instead')
            this.setState({
                name: '',
                email: '',
                message: '',
                lastname: '',
                error: {}
            })
        }
    }

    render(){
        const { name,
            email,
            subject,
            lastname,
            error } = this.state;

        return(
            <form onSubmit={this.submitHandler} className="form">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Name"/>
                            <p>{error.name ? error.name : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="form-field">
                            <input value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder="Lastname"/>
                            <p>{error.lastname ? error.lastname : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email"/>
                            <p>{error.email ? error.email : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea name="message" onChange={this.changeHandler} placeholder="Message"></textarea>
                            <p>{error.message ? error.message : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="theme-btn">Send Message</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

}
export default  ContactForm;