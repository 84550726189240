import React from 'react'
import {Link} from 'react-router-dom'

const Services = [
    {
        id: '5',
        fIcon1:'flaticon-elephant',
        title:'Good Financial Behaviour',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    },
    {
        id: '6',
        fIcon1:'flaticon-ecology', // Building
        title:'Positive Community Support Structure',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    },
    {
        id: '7',
        fIcon1:'flaticon-tiger',
        title:'Growing generational wealth',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    }
]

const Service2 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(

        <section className="wpo-features-section-s3 section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="wpo-section-title">
                            <h2>Our Key Objectives</h2>
                            <p>The DHF’s mandate and definition of success is achieving the following objectives</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {Services.map((service, sitem) => (
                        <div className="col col-xl-4 col-lg-6 col-sm-6 col-12" key={sitem}>
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i className={`fi  ${service.fIcon1}`}></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2><Link onClick={ClickHandler} to='#'>{service.title}</Link></h2>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Service2;