import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logo2.png'
import Projects from '../../api/projects'

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="wpo-site-footer">
        <div className="wpo-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                <img src={Logo} alt="blog"/>
                            </div>
                            <p>Empowerment through practical financial education.</p>
                            <ul>
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/Dreamers-Hub-Foundation-107327898586193/">
                                        <i className="ti-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com/DreamersHubFou1">
                                        <i className="ti-twitter-alt"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/dreamershub_foundation/">
                                        <i className="ti-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col col-md-6 col-sm-12 col-12">
                        <div className="widget wpo-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                            <div className="contact-ft">
                                <p>Would you have any enquiries. Please feel free to contact us</p>
                                <ul>
                                    <li><i className="fi flaticon-mail"></i>hello@dreamershub.org</li>
                                    <li><i className="fi flaticon-location"></i>Hamilton, ON, Canada</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="copyright"> &copy; {new Date().getFullYear()} Dreamers Hub Inc. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;