import React from 'react'
import {Link} from 'react-router-dom'
import eimg3 from '../../images/campaign/5.jpg'
import gifImg from '../../images/EnhanceInitiative.gif'
import gifImg2 from '../../images/lady-mentor.jpg'

const Causes = [
    {
        id: '4',
        process:'95',
        thumb:'Grow',
        cTitle: 'The Enhance Initiative', 
        description:'Enhance is a new initiative by the DHF that provides a comprehensive online learning centre and a community support program for young adults from black communities.',
        cImg:gifImg,

    },
    {
        id: '5',
        process:'55',
        thumb:'Connect',
        cTitle: 'Mentorship Program',
        cImg:gifImg2,
        description:'The DHF mentorship program connects individuals with subject matter experts across the country, enabling them access to a pool of expert and practical knowledge across multiple industries.',
    }
]

const CauseSection2 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(

        <div className={`wpo-campaign-area section-padding ${props.CmClass}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="wpo-section-title">
                            <h2>Our Current Initiatives</h2>
                        </div>
                    </div>
                </div>
                <div className="wpo-campaign-wrap">
                    <div className="row">
                    {Causes.map((Cause, citem) => (
                        <div className="col-lg-4 col-md-6 col-12" key={citem}>
                            <div className="wpo-campaign-single">
                                <div className="wpo-campaign-item">
                                    <div className="wpo-campaign-img">
                                        <img src={Cause.cImg} alt=""/>
                                        <span className="thumb">{Cause.thumb}</span>
                                    </div>
                                    <div className="wpo-campaign-content">
                                        <div className="wpo-campaign-text-top">
                                            <h2><Link to="#">{Cause.cTitle}</Link></h2>
                                            <p>{Cause.description}</p>
                                            <div className="campaign-btn">
                                                <ul>
                                                    <li><Link className="e-btn" to="#">Coming Soon</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CauseSection2;