import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'
import img1 from '../../images/nkem-bio.jpeg'
import img2 from '../../images/seun-bio.jpeg'
import img3 from '../../images/kingsley-pic.jpeg'

const Teams = [
    {
       id:'1',
       tImg:img3,
       name:'Kingsley Okeke',
       title:'Founder',     
    },
    {
       id:'2',
       tImg:img2,
       name:'Seun Ajala',
       title:'Founder',     
    },
    {
       id:'3',
       tImg:img1,
       name:'Nkem Obinyelaku',
       title:'Founder',     
    }
]


class TeamSection extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: false,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div className="wpo-team-area section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="wpo-section-title">
                                <h2>Meet The Team</h2>
                                <p>Our team is passionate about creating a positive impact in people’s lives.</p>
                            </div>
                        </div>
                    </div>
                    <div className="wpo-team-wrap">
                        <div className="team-slider">
                            <Slider {...settings}>
                                {Teams.slice(0,6).map((Team, tm) => (
                                    <div className="wpo-team-item" key={tm}>
                                        <div className="wpo-team-img">
                                            <img src={Team.tImg} alt=""/>
                                        </div>
                                        <div className="wpo-team-content">
                                            <h2><Link to="#">{Team.name}</Link></h2>
                                            <span>{Team.title}</span>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamSection;