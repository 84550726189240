import timg1 from '../images/team/1.jpg'
import timg2 from '../images/team/2.jpg'
import timg3 from '../images/team/3.jpg'
import timg4 from '../images/team/4.jpg'
import timg5 from '../images/team/5.jpg'
import timg6 from '../images/team/6.jpg'
import timg7 from '../images/team/7.jpg'
import timg8 from '../images/team/8.jpg'
import timg9 from '../images/team/9.jpg'
import timg10 from '../images/team/10.jpg'


const Teams = [
    {
       id:'1',
       tImg:timg1,
       name:'Angeica Laura',
       title:'Volunteer',     
    },
    {
       id:'2',
       tImg:timg2,
       name:'Maria Belziana',
       title:'Volunteer',     
    },
    {
       id:'3',
       tImg:timg3,
       name:'Robert Chirls',
       title:'Team Leader',     
    }
]

export default Teams;